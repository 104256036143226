<template>
  <el-dialog
    ref="dialog"
    title="校验白名单数据"
    :model-value="dialogVisible"
    :append-to-body="true"
    :before-close="handleClose"
    width="500px"
    :close-on-click-modal="false"
    :z-index="1000"
  >
    <el-form :rules="rules" ref="form" :model="checkForm" label-width="140px">
      <el-form-item label="MD5值或手机号" prop="value">
        <el-input placeholder="请输入MD5值或手机号" v-model="checkForm.value"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="loading" :loading="loading">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'
import whiteModel from '@/model/white-list'

export default {
  emits: ['confirm'],
  setup() {
    const dialogVisible = ref(false)
    const loading = ref(false)
    const curItem = ref(false)
    const checkForm = ref({})
    const form = ref(null)
    const rules = ref({
      value: [{ message: '请输入MD5值或手机号', trigger: 'blur', required: true }],
    })

    const init = item => {
      dialogVisible.value = true
      checkForm.value = {}
      curItem.value = item
    }

    const handleConfirm = () => {
      form.value.validate(async valid => {
        if (valid) {
          let res
          try {
            loading.value = true
            res = await whiteModel.checkWhiteList(curItem.value.id, {
              ...checkForm.value,
            })
            ElMessage[res.exist_flag ? 'success' : 'warning'](res.exist_flag ? '该数据存在' : '该数据不存在')
          } finally {
            loading.value = false
          }
        }
      })
    }

    const handleClose = done => {
      dialogVisible.value = false
      loading.value = false
      form.value.resetFields()
      if (typeof done === 'function') done()
    }

    return {
      dialogVisible,
      loading,
      rules,
      checkForm,
      form,
      init,
      handleClose,
      handleConfirm,
    }
  },
}
</script>

<style></style>
